
import { createVNode } from "vue";
import {
  ExclamationCircleOutlined,
  DeleteOutlined,
  PlusOutlined,
  DownOutlined,
  DownloadOutlined,
  CalendarOutlined,
  UpOutlined,
  RedoOutlined,
  UploadOutlined
} from "@ant-design/icons-vue";
import Detail from "./compontent/detail.vue";
import OrderUpload from "./compontent/order_upload.vue";
import { mapState } from "vuex";
import config from "./config.js";

export default {
  name: "OrderOrder",
  components: {
    DeleteOutlined,
    PlusOutlined,
    DownOutlined,
    DownloadOutlined,
    CalendarOutlined,
    UpOutlined,
    RedoOutlined,
    UploadOutlined,
    Detail,
    OrderUpload
  },
  data() {
    return {
      url: "/user/order/list",
      // 表格搜索条件
      where: {},
      // 表格选中数据
      selection: [],
      // 当前编辑数据
      current: null,
      // 显示详情
      showDetail: false,
      // 物流渠道列表
      ship_methods: [],
      // 搜索表单是否展开
      searchExpand: false,
      // 导出loading状态
      exportLoading: false,
      // 导入订单
      showImportModal: false,
      // 文件导入
      fileList: [],
      // 显示备注
      visibleRemark: false,
      // 保存备注loading
      loadingRemark: false
    };
  },
  computed: {
    ...mapState("system", {
      sys_config: state => state.config
    }),
    tracking_number_list: {
      get() {
        return this.where.tracking_number
          ? this.where.tracking_number.split(",")
          : [];
      },
      set(val) {
        this.where.tracking_number = val.join(",");
      }
    },
    voidSelection() {
      return this.selection.filter(item => ![1, 3].includes(item.order_status));
    }
  },
  activated() {
    this.reload();
  },
  mounted() {
    this.load_ship_methods();
  },
  methods: {
    /**
     * 提示回调
     */
    handleErrorTip(row) {
      this.$http.get(`/admin/order/message/${row.id}`).then(res => {
        row.label_error_message = res.data.msg;
      });
    },
    /**
     * 加载物流渠道列表
     */
    load_ship_methods() {
      this.$http
        .get("/admin/ship_method/simple_list")
        .then(res => {
          if (res.data.code === 0) {
            this.ship_methods = res.data.data;
          } else {
            this.$message.error(res.data.msg);
          }
        })
        .catch(e => {
          this.$message.error(e.message);
        });
    },
    /**
     * 搜索
     */
    reload() {
      this.selection = [];

      this.$refs.table.reload({
        page: 1
      });
    },
    /**
     * 重置搜索
     */
    reset() {
      this.where = {};
      this.$nextTick(() => {
        this.reload();
      });
    },
    /**
     * 导出Excel
     */
    exportExcel() {
      this.exportLoading = true;
      const ids = this.selection.map(item => item.id).join(","),
        form = Object.assign(
          {
            ids
          },
          this.where
        );
      !form.ids && delete form.ids;

      this.$http
        .post("/user/order/export", form)
        .then(res => {
          this.exportLoading = false;

          if (res.data.code === 0) {
            const blob = this.base64ToBlob(
              res.data.data,
              "application/vnd.ms-excel"
            );
            const fileName = `Orders-${new Date().getTime()}.xls`;

            if ("download" in document.createElement("a")) {
              // 非IE下载
              const el = document.createElement("a");

              el.download = fileName;
              el.style.display = "none";
              el.href = URL.createObjectURL(blob);
              document.body.appendChild(el);

              el.click();

              URL.revokeObjectURL(el.href);
              document.body.removeChild(el);
            } else {
              // IE10+下载
              navigator.msSaveBlob(blob, fileName);
            }
          } else {
            this.$message.error(res.data.msg);
          }
        })
        .catch(e => {
          this.exportLoading = false;
          this.$message.error(e.message);
        });
    },
    /**
     * 取消单个
     */
    void(row) {
      const hide = this.$message.loading("share.requesting", 0);

      this.$http
        .post("/user/order/void", {
          ids: [row.id]
        })
        .then(res => {
          hide();
          if (res.data.code === 0) {
            this.$message.success(res.data.msg);
            this.reload();
          } else {
            this.$message.error(res.data.msg);
          }
        })
        .catch(e => {
          hide();
          this.$message.error(e.message);
        });
    },
    /**
     * 批量删除
     */
    voidBatch() {
      if (!this.voidSelection.length) {
        return this.$message.error("share.select_one_more_then");
      }

      this.$confirm({
        title: this.$t("share.tips"),
        content: this.$t("user_order.void_confirm"),
        icon: createVNode(ExclamationCircleOutlined),
        maskClosable: true,
        onOk: () => {
          const hide = this.$message.loading("share.requesting", 0);

          this.$http
            .post("/user/order/void", {
              ids: this.voidSelection.map(d => d.id)
            })
            .then(res => {
              hide();
              if (res.data.code === 0) {
                this.$message.success(res.data.msg);
                this.reload();
              } else {
                this.$message.error(res.data.msg);
              }
            })
            .catch(e => {
              hide();
              this.$message.error(e.message);
            });
        }
      });
    },
    /**
     * 下载Label
     */
    downloadLabel(rows, key) {
      if (!rows.map && !this.selection.length) {
        return this.$message.error("share.select_one_more_then");
      }

      !rows.map && (rows = this.selection);
      const hide = this.$message.loading("share.requesting", 0);
      this.$http
        .post(`/user/order/${key}`, {
          ids: rows.map(d => d.id)
        })
        .then(res => {
          hide();
          if (res.data.code === 0) {
            const blob = this.base64ToBlob(
              res.data.data,
              res.data.msg == ".zip"
                ? "application/x-zip-compressed"
                : "application/pdf"
            );

            let dymaicName = "";

            const weighLsit = [
              "10669.lbl.morelink56.com",
              "dmyc.lbl.morelink56.com",
              "jtgj.lbl.morelink56.com"
            ];

            // 飞啊飞啊定制
            if (weighLsit.includes(this.$setting.env)) {
              dymaicName = rows[0].customer_reference || rows[0].po_number;
            } else if (this.$setting.env === "wwzl.lbl.morelink56.com") {
              dymaicName = rows[0].master_tracking_number;
            } else if (this.$setting.env === "hygj.lbl.morelink56.com" || this.$setting.env.includes("hyfba.com.cn") || this.$setting.env === "hygj") {
              dymaicName = rows[0].reference1 || rows[0].po_number;
            } else {
              dymaicName = rows[0].po_number;
            }

            let fileName = `${rows.length === 1 ? dymaicName : "labels x" + rows.length
              }${res.data.msg == ".zip" ? ".zip" : ".pdf"}`;

            if ("download" in document.createElement("a")) {
              // 非IE下载
              const el = document.createElement("a");

              el.download = fileName;
              el.style.display = "none";
              el.href = URL.createObjectURL(blob);
              document.body.appendChild(el);

              el.click();

              URL.revokeObjectURL(el.href);
              document.body.removeChild(el);
            } else {
              // IE10+下载
              navigator.msSaveBlob(blob, fileName);
            }
          } else {
            if (res.data.msg.includes("OnlyURL")) {
              const el = document.createElement("a");
              el.href = res.data.extend.URL;
              el.target = "_blank";
              el.download = "label.pdf";
              el.click();
            } else {
              this.$message.error(`${res.data.msg}`);
            }
          }
        })
        .catch(e => {
          hide();
          this.$message.error(e.message);
        });
    },
    /**
     * 下拉菜单点击事件
     */
    dropClick(command, record) {
      let command_methods = {
        void: () => {
          this.$confirm({
            title: this.$t("share.tips"),
            content: this.$t("user_order.void_confirm"),
            icon: createVNode(ExclamationCircleOutlined),
            maskClosable: true,
            onOk: () => this.void(record)
          });
        },
        reset_void: () => {
          this.$http
            .get(`/user/order/reset_void/${record.id}`)
            .then(res => {
              if (res.data.code === 0) {
                this.$message.success(res.data.msg);
                this.reload();
              } else {
                this.$message.error(res.data.msg);
              }
            })
            .catch(e => {
              this.$message.error(e.message);
            });
        },
        openEdit: () => {
          this.$router.push({
            path: "/user/order/build",
            query: {
              id: record.id
            }
          });
        },
        rebuild: () => {
          this.$router.push({
            path: "/user/order/build",
            query: {
              id: record.id,
              rebuild: 1
            }
          });
        },
        downloadLabel: () => {
          this.downloadLabel([record], "label");
        },
        openDetail: () => {
          this.current = record;
          this.showDetail = true;
        },
        startClaim: () => {
          this.$router.push({
            path: "/user/order/claims/ups",
            query: {
              id: record.id
            }
          });
        },
        reCreate: () => {
          const hide = this.$message.loading("share.requesting", 0);
          this.$http
            .get(`/user/order/create_label/${record.id}`)
            .then(res => {
              hide();
              if (res.data.code == 0) {
                this.reload();
              }
            })
            .finally(() => { });
        },
        downloadAttachment: () => {
          this.downloadLabel([record], "attachment");
        },
        reTry: () => {
          this.reTry(record);
        },
        addRemark: () => {
          this.current = record;
          this.visibleRemark = true;
        }
      };

      command_methods[command]();
    },
    base64ToBlob(text, content_type) {
      var byteString = atob(text);
      var arrayBuffer = new ArrayBuffer(byteString.length);
      var intArray = new Uint8Array(arrayBuffer);

      for (var i = 0; i < byteString.length; i++) {
        intArray[i] = byteString.charCodeAt(i);
      }

      return new Blob([intArray], {
        type: content_type
      });
    },
    /* 重建面单 */
    reCreatLabel(record) {
      const hide = this.$message.loading("share.requesting", 0);
      this.$http
        .get(`/user/order/create_label/${record.id}`)
        .then(res => {
          hide();
          if (res.data.code == 0) {
            this.reload();
          } else {
            this.$message.error(res.data.msg);
          }
        })
        .catch(e => {
          hide();
          this.$message.error(e.message);
        });
    },
    /* 更改下拉选择 */
    handleChangeTN(arr) {
      const length = arr.length;
      if (length <= 0) return;
      const val = arr[length - 1];

      if (val.indexOf(" ") > -1) {
        arr.splice(length - 1, 1);
        arr.push(...val.split(" "));
        this.tracking_number_list = arr;
      }
    },
    /* 粘贴动作 */
    handlePasting(e) {
      this.where.tracking_number_patse = [
        ...e.clipboardData
          .getData("text")
          .split(/[\r\n]|[,]|[ ]|[，]|[、]/)
          .filter(item => item),
        ...this.tracking_number_list
      ];
    },
    /**
     * 提交备注
     */
    submitRemark() {
      this.loadingRemark = true;
      this.$http
        .post("/user/order/remark", {
          order_id: this.current.id,
          remark: this.current.remark
        })
        .then(res => {
          if (res.data.code === 0) {
            this.current = null;
            this.visibleRemark = false;
            this.$message.success(res.data.msg);
          } else {
            this.$message.error(res.data.msg);
          }
        })
        .catch(e => {
          this.$message.error(e.message);
        })
        .finally(() => {
          this.loadingRemark = false;
        });
    }
  },
  setup() {
    const { columns } = config();

    return {
      columns
    };
  }
};
